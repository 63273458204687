<template>
  <div>
    <h1>Sobre esta página.</h1>
    <p>
      <span class="text-yellow">Gasolineras.app</span> es una web informativa, que pretende ofrecer
      una información veraz y de forma clara y sencilla.
    </p>
    <p>
      No almacenamos ningún dato personal ni de navegación de nuestros
      usuarios. Le pedimos la información de posición de su dispositivo
      con el único propósito de ofrecerle las <b>gasolineras mas cercanas</b>
      e informarle sobre la distancia de las mismas. Creemos que es una
      información importante.
    </p>
    <p>
      Actualizamos el precio de los carburantes regularmente.
    </p>
  </div>
</template>
<style>
p{
  @apply my-7 text-justify;
}
</style>
<script>
export default {
  name: 'About'
}
</script>
